<template>
  <div id="career-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="career-cast">
        <div class="text-left career-btn" @click="$emit('back-tutorial')">
          <img
            class="btn-back"
            :src="require('@/assets/image/arrow-back.svg')"
            alt=""
          />
        </div>
        <div class="career-tutorial d-flex justify-content-center">
          <div class="career f-maru">
            <p class="title f-maru">あなたのことを教えてください！</p>
            <div class="career__div text-left">
              <p class="career__p f-maru">身長</p>
              <div class="height">
                <SelectOption
                  :propsOptions="heights"
                  :propsName="'身長'"
                  :propsShow="show"
                  :propsRules="{
                    required: true,
                    numeric: true
                  }"
                  :propsStyle="'small'"
                  @emitOption="chooseOption($event, 'height')"
                  @showOption="showOption(1)"
                  :propsId="'height'"
                  v-model="form.height"
                />
              </div>
              <span v-if="error_height" class="error">{{ error_height }}</span>
            </div>
            <div class="career__div text-left">
              <p class="career__p f-maru">職業</p>
              <SelectOption
                :propsOptions="careers"
                :propsName="'職業'"
                :propsRules="{
                  required: true
                }"
                :propsStyle="'small'"
                @emitOption="chooseOption($event, 'job')"
                @showOption="showOption(2)"
                :propsId="'job'"
                :propsShow="showJob"
                v-model="form.job"
              />
              <span v-if="error_job" class="error">{{ error_job }}</span>
            </div>
            <button type="submit" @click="nextTutorial">
              <div
                class="position-relative d-flex align-items-center button-back"
              >
                <div class="text-center posision-absolute w-100 text-center">
                  次へ
                </div>
                <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import SelectOption from "@/components/SelectOption";

export default {
  name: "CareerTutorialMen",
  components: {
    SelectOption: SelectOption
  },
  props: {
    propsOptions: {
      type: Array,
      default: null
    }
  },
  created() {
    this.careers = this.propsOptions.map(function(option) {
      return {
        name: option.name,
        value: option.occupation_id
      };
    });
    for (let i = 140; i <= 220; i++) {
      this.heights.push({
        name: i,
        value: i
      });
    }
  },
  data() {
    return {
      heights: [],
      error_height: null,
      error_job: null,
      show: false,
      showJob: false,
      form: {
        height: "",
        job: ""
      }
    };
  },
  methods: {
    blur(e) {
      if (!e.target.closest("#height")) {
        this.show = false;
      }
      if (!e.target.closest("#job")) {
        this.showJob = false;
      }
    },
    showOption(type) {
      if (type == 1) {
        this.show = !this.show;
        return;
      }
      this.showJob = !this.showJob;
    },
    chooseOption(data, name) {
      this.form[name] = data;
      this.reflectData(name);
      if (name == "height") {
        this.show = !this.show;
        this.error_height = null;
        return;
      }
      this.error_job = null;
      this.showJob = !this.showJob;
    },
    nextTutorial() {
      if (this.form.height == "") {
        this.error_height = "身長は必須項目です";
      }
      if (this.form.job == "") {
        this.error_job = "職業は必須項目です";
      }
      if (this.form.height != "" && this.form.job != "")
        this.$emit("tutorialCareer", this.form);
    },
    reflectData(data) {
      this.$emit("emitReflect", {
        name: data,
        value: this.form[data]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/careerTutorial.scss";
@import "@/assets/scss/_fontFamily.scss";
#career-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
}
</style>
