<template>
  <div id="complete-men">
    <AppContainer :useBackground="true">
      <div class="complete-cast">
        <div class="text-left" @click="$emit('back-tutorial')">
          <img
            class="btn-back"
            :src="require('@/assets/image/arrow-back.svg')"
            alt=""
          />
        </div>
        <div class="text-center complete-tutorial">
          <img
            src="@/assets/image/home_top/prego.svg"
            class="logo-prego"
            alt=""
          />
          <div class="complete-tutorial__next f-score" @click="nextTutorial">
            START
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "ProfileEditScreenDetailsTwelve",
  data() {
    return {};
  },
  methods: {
    nextTutorial() {
      this.$emit("tutorialComplete");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialComplete.scss";
#complete-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
}
</style>
