<template>
  <div>
    <p class="mt-3 mb-3 f-w6">こちらでよろしいですか？</p>
    <div class="d-flex justify-content-center w-100">
      <div class="image-profile">
        <img :src="propsUser.image_url" class="w-100; h-100" alt="" />
      </div>
    </div>
    <div class="d-flex justify-content-center image-profile__btn mt-3">
      <button @click.prevent="acceptCrop()" class="btn-logout">はい</button>
      <button @click="closeModal()" class="btn-cancel-logout">いいえ</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageProfile",
  props: {
    propsUser: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isJapanFont: false,
      rank: null
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    acceptCrop() {
      this.$emit("acceptModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.image-profile {
  height: 316px;
  width: 316px;
  border-radius: 5px;
  pointer-events: none;
}

.btn-logout {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 20px;
}
.btn-cancel-logout {
  width: 155px;
  height: 45px;
  color: #000000;
  background-color: #e4e4e4;
  font-size: 16px;
  border-radius: 5px;
}

@media screen and (min-width: 1080px) {
  .image-profile {
    width: 100%;
    height: 400px;
    width: 400px;
  }
}
</style>
