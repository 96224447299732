<template>
  <div id="dating-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="text-center d-flex justify-content-center">
        <div class="profile-edit-ten">
          <div class="text-left" @click="$emit('back-tutorial')">
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-back.svg')"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(nextTutorial)">
              <label for="" class="text-center f-maru">
                あなたのことを教えてください！
              </label>
              <ValidationProvider name="交際" rules="" v-slot="{ errors }">
                <div class="position-relative m-auto focus-div" id="dating">
                  <div class="profile-edit-ten__input m-auto">
                    <div class="profile-edit-ten__input-label text-left f-maru">
                      交際
                    </div>
                    <div
                      class="
                        profile-edit-ten__input-select
                        m-auto
                        position-relative
                      "
                      :class="isShowListDating ? 'active' : ''"
                      @click="showListDating"
                    >
                      <div
                        type="text"
                        class="f-maru input d-flex justify-content-start align-items-center"
                      >
                        {{ datingSelected.name }}
                      </div>
                    </div>
                  </div>
                  <transition name="slide">
                    <ul
                      class="
                        profile-edit-ten__listDating
                        text-left
                        position-absolute
                      "
                      v-if="isShowListDating"
                    >
                      <li
                        class="profile-edit-ten__listDating-item f-maru"
                        v-for="(item, index) in relationshipState"
                        :key="index"
                        @click="selectOptionDating(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </transition>
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button
                type="submit"
                class="profile-edit-ten__next position-relative f-maru"
              >
                次へ
                <img
                  src="@/assets/image/arrow-right-submit.svg"
                  class="icon position-absolute"
                  alt=""
                />
              </button>
              <div class="profile-edit-ten__skip f-maru" @click="nextTutorial">
                スキップ
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "DatingMen",
  data() {
    return {
      datingSelected: "",
      isShowListDating: false,
      relationshipState: []
    };
  },
  watch: {
    datingSelected: function() {
      this.$emit("showDatingStatus", this.datingSelected);
    }
  },
  props: {
    relationship: Array,
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  methods: {
    blur(e) {
      if (!e.target.closest("#dating")) {
        this.isShowListDating = false;
      }
    },
    nextTutorial() {
      this.$emit("tutorialDating");
    },
    showListDating() {
      this.relationshipState = this.relationship;
      this.isShowListDating = !this.isShowListDating;
      this.$nextTick(() => {
        let listOptions = document.querySelectorAll(
          ".profile-edit-ten__listDating-item"
        );
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.datingSelected.name) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
            listOptions[i].scrollIntoView(false);
          }
        }
      });
    },
    selectOptionDating(item) {
      if (this.datingSelected == item) {
        this.datingSelected = "";
        this.isShowListDating = !this.isShowListDating;
        return;
      }
      this.datingSelected = item;
      this.isShowListDating = !this.isShowListDating;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialDating.scss";
#dating-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
  .slide-enter,
  .slide-leave-to {
    transform: scaleY(0);
  }
  .focus-div {
    outline: none;
    box-shadow: none;
  }
}
</style>
