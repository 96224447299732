var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-div",attrs:{"id":_vm.propsId}},[_c('div',{staticClass:"select-option"},[_c('div',{staticClass:"select select--small position-relative"},[_c('div',{staticClass:"select__div d-flex align-items-center",class:_vm.isShowOptions ? 'actived' : '',on:{"click":_vm.showOptions}},[_c('div',{staticClass:"\n            select__input\n            f-maru\n            select__input--medium\n            d-flex\n            align-items-center\n          "},[_vm._v(" "+_vm._s(_vm.text)+" ")])]),_c('transition',{attrs:{"name":"slide"}},[(_vm.propsShow)?_c('ul',{staticClass:"select__ul select__ul--medium",attrs:{"id":"select"}},_vm._l((_vm.propsOptions),function(option,index){return _c('li',{key:index,staticClass:"select__li f-maru",class:{
              active:
                (Array.isArray(_vm.value) && _vm.value.includes(option.value)) ||
                _vm.value === option.value,
              disabled:
                !(
                  (Array.isArray(_vm.value) && _vm.value.includes(option.value)) ||
                  _vm.value === option.value
                ) && _vm.value.length >= 5,
              'select__li--medium': _vm.propsStyle === 'medium',
              'select__li--area': _vm.propsId != 'area'
            },on:{"click":function($event){return _vm.chooseOption(option)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }