<template>
  <div id="avatar-men">
    <!--    <AppContainer :useBackground="true">-->
    <div class="tutorial-notification-image d-flex justify-content-center">
      <ModalLayout ref="modal-avatar">
        <ImageProfile
          :propsUser="propsUser"
          @acceptModal="acceptModal"
          @closeModal="closeModal"
        />
      </ModalLayout>
      <div class="edit-profile-vue-cropper" v-if="isShowCropper">
        <Cropper
          class="upload-example-cropper"
          :src="imageTest"
          @change="onChange"
          ref="cropper"
          :stencil-props="{
            aspectRatio: aspectRatio1
          }"
          :stencil-size="stencilSize"
          imageRestriction="stencil"
        />
        <div class="d-flex btn-option justify-content-center">
          <div class="f-w3 mr-3 btn-cancel">
            <button @click="cancel()" class="btn">キャンセル</button>
          </div>
          <div class="f-w3 btn-success">
            <button @click="down()" class="btn">登録する</button>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="content">
          <div
            class="text-left"
            @click="$emit('back-tutorial')"
            v-if="auth.login_type == 'LINE'"
          >
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-right-color.svg')"
              alt=""
            />
          </div>
          <process-bar-profile :processStep="processStep" />
          <div class="upload-img-title f-w6">
            <p>アイコンの設定をしましょう</p>
          </div>
          <!--            <div-->
          <!--              class="-->
          <!--                text-center-->
          <!--                d-flex-->
          <!--                justify-content-center-->
          <!--                align-items-center-->
          <!--                choose-image-->
          <!--              "-->
          <!--            >-->
          <!-- <img
                src="@/assets/image/icon_profile/icon-plus.svg"
                class="icon"
                v-if="!avatar"
              /> -->
          <!--              <div class="golf-avatar" />-->

          <!--            </div>-->
          <div class="golf-user">
            <div class="w-100">
              <img
                src="@/assets/image/icon_mypage/menSP2x.png"
                class="avatar-men"
                alt=""
                v-if="auth.sex == 1"
              />
              <img
                src="@/assets/image/icon_mypage/castSP2x.png"
                class="avatar-men"
                alt=""
                v-if="auth.sex == 2"
              />
            </div>
          </div>
          <div class="tutorial-notification__div f-maru introduce__img">
            <p>表情が分かるアイコンだと</p>
            <p>ゴルフに誘われやすいです</p>
            <label
              class="
                  d-flex
                  align-items-center
                  button-back
                  justify-content-center
                  position-relative
                  label--top
                  f-maru
                  btn-choose-img
                "
            >
              <b-form-file
                class="d-none"
                ref="form-file"
                v-on:change="chooseImage($event)"
                accept="image/jpeg, image/png, image/jpg"
              />
              <div class="text-center posision-absolute w-100">
                ライブラリから選択する
              </div>
              <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
            </label>
            <label
              v-if="isShowCamera"
              class="
                  d-flex
                  camera
                  align-items-center
                  button-back button-back--bottom
                  justify-content-center
                  position-relative
                "
            >
              <b-form-file
                class="d-none"
                capture="camera"
                ref="form-file-camera"
                v-on:change="chooseImage($event)"
                accept="image/jpeg, image/png, image/jpg"
              />
              <div
                class="text-center posision-absolute w-100 text-center f-maru"
              >
                写真を撮る
              </div>
              <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
            </label>
            <div
              v-else
              class="text-center position-relative snap f-maru"
              @click="stream"
            >
              写真を撮る
              <img
                src="@/assets/image/icon-next.svg"
                class="position-absolute"
                alt=""
                id="icon"
              />
            </div>
            <div class="video" v-show="showVideo">
              <video
                id="video"
                ref="camera"
                width="640"
                height="480"
                autoplay
              ></video>
              <br />
              <div class="d-flex justify-content-center mt-3">
                <button id="cancel" @click="cancelVideo">キャンセル</button>
                <button id="snap" @click="snap">撮る</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!--    </AppContainer>-->
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import ImageProfile from "@/views/imageProfile";
import { mapGetters } from "vuex";
import ProcessBarProfile from "@/components/ProcessBarProfile";
import { MAX_IMAGE_SIZE, ALLOW_FILE_TYPE_IMAGE } from "@/definition/constants";

export default {
  name: "TutorialNotificationImage",
  components: {
    Cropper,
    ImageProfile,
    "process-bar-profile": ProcessBarProfile
  },
  data() {
    return {
      propsUser: {
        image_url: null,
        rank: null
      },
      avatar: null,
      isShowCamera: false,
      aspectRatio1: 16 / 16,
      minAspectRatio: 335,
      maxAspectRatio: 335,
      result: null,
      isShowCropper: false,
      imageTest: null,
      blod: null,
      showVideo: false,
      processStep: 2
    };
  },
  created() {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      window.innerWidth <= 699
    ) {
      this.isShowCamera = true;
      setTimeout(() => {
        this.$refs["form-file"].reset();
        this.$refs["form-file-camera"].reset();
      }, 500);
    } else {
      this.isShowCamera = false;
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user"
    })
  },
  methods: {
    async stream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.showVideo = true;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          this.showVideo = false;
          console.log(error);
          this.$toast("カメラを起動できませんでした。", "通知", "danger");
        });
    },
    snap() {
      var canvas = document.createElement("canvas");
      canvas.width = 640;
      canvas.height = 480;
      var context = canvas.getContext("2d");
      var video = document.getElementById("video");

      // Trigger photo take

      context.drawImage(video, 0, 0, 640, 480);
      this.imageTest = canvas.toDataURL("image/jpeg");
      this.showVideo = false;
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
      this.isShowCropper = true;
      // this.$emit("createImage", blod);
      // this.$refs["modal-avatar"].openModal();
    },
    cancelVideo() {
      this.showVideo = false;
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },
    onChange({ coordinates, canvas }) {
      this.coordinates = coordinates;
      this.result = canvas.toDataURL("image/jpeg");
    },
    cancel() {
      this.avatar = null;
      this.isShowCropper = false;
    },
    async down() {
      // this.$root.$refs.loading.start();
      const base64Response = await fetch(this.result);
      this.blod = await base64Response.blob();
      var img = await window.URL.createObjectURL(this.blod);
      this.propsUser.image_url = img;
      // this.$emit("createImage", blod);
      this.$refs["modal-avatar"].openModal();
    },
    closeModal() {
      this.$refs["modal-avatar"].closeModal();
    },
    async acceptModal() {
      this.$root.$refs.loading.start();
      const base64Response = await fetch(this.result);
      const blod = await base64Response.blob();
      this.$refs["modal-avatar"].closeModal();
      this.$emit("createImage", blod);
    },
    stencilSize() {
      return {
        width: this.minAspectRatio,
        height: this.maxAspectRatio
      };
    },
    defaultSize() {
      return {
        width: "237px",
        height: "316px"
      };
    },
    chooseImage(event) {
      const uploadFile = event.target.files[0];
      if (!ALLOW_FILE_TYPE_IMAGE.includes(uploadFile.type)) {
        this.$toast(
          "無効な画像形式. png、jpg、jpeg形式の画像を選択してください",
          "エラー",
          "danger"
        );
        return;
      }
      if (uploadFile.size > MAX_IMAGE_SIZE) {
        this.$toast(
          "プロフィール写真は3MB以内にしてください",
          "エラー",
          "danger"
        );
        return;
      }
      let form = new FormData();
      form.append("avatar", event.target.files[0]);
      for (var value of form.values()) {
        // this.$emit("uploadImage", form);
        var reader = new FileReader();
        var vm = this;
        reader.onload = e => {
          let data;
          if (typeof e.target.result === "object") {
            data = window.URL.createObjectURL(new Blob([e.target.result]));
          } else {
            data = e.target.result;
          }
          vm.imageTest = data;
          vm.isShowCropper = true;
        };
        reader.readAsDataURL(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialNotificationImage";
@import "@/assets/scss/_fontFamily.scss";
.video {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  max-width: 1080px;
}
#avatar-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    //background-image: url("~@/assets/image/icon_profile/profile-bgr-l.png");
  }
  background-image: url("~@/assets/image/background/bgr-pc.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
  .upload-img-title {
    font-size: 25px;
    margin-top: 25px;
    font-weight: 600;
    color: #ffffff;
  }

  .introduce__img {
    color: #ffffff;
  }

  .golf-user {
    img {
      width: 430px;
      height: 430px;
    }
  }
}
#video {
  margin-top: 50px;
}
.snap {
  width: 430px;
  height: 50px;
  font-size: 20px;
  background-color: #464d77;
  margin: auto;
  border-radius: 4.1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
}
#icon {
  width: 10.77px;
  height: 20px;
  right: 17px;
}
#cancel {
  height: 50px;
  width: 210px;
  margin-right: 20px;
  font-size: 16px;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #dbdbdb;
  color: #000000;
}
#snap {
  height: 55px;
  width: 210px;
  font-size: 16px;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #464d77;
  color: #ffffff;
}
</style>

<style lang="scss" scoped>
#avatar-men {
  /deep/.edit-profile-vue-cropper {
    position: fixed;
    top: 0px !important;
    bottom: 0;
    width: 100%;
    z-index: 12 !important;
    background-color: #fff;
    max-width: 1080px;
    .upload-example-cropper {
      height: calc(100% - 90px);
    }
    .btn-cancel {
      background-color: #dbdbdb;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      margin-right: 11px;
      .btn {
        width: 100%;
        height: 100%;
      }
    }
    .btn-success {
      background-color: #464d77;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      .btn {
        color: #fff;
        width: 100%;
        height: 100%;
      }
    }
    .btn-option {
      background-color: white;
      position: relative;
      z-index: 10;
      padding: 20px 0;
    }
  }
}
@media screen and (min-width: 1080px) {
  #avatar-men {
    /deep/.edit-profile-vue-cropper {
      top: 0 !important;
      .upload-example-cropper {
        height: calc(100% - 173px);
      }
      .btn-cancel {
        height: 55px;
        width: 210px;
        margin-right: 20px;
        font-size: 20px;
      }
      .btn-success {
        height: 55px;
        width: 210px;
        font-size: 20px;
      }
      .btn-option {
        padding: 35px 0;
      }
    }
  }
}

@media screen and (max-width: 1079px) {
  #avatar-men {
    .upload-img-title {
      font-size: 20px;
      margin-bottom: -10px;
    }
    .snap {
      width: 330px;
      height: 45px;
      font-size: 17px;
    }

    .btn-choose-img {
      width: 330px;
    }

    .button-back {
      width: 330px !important;
    }

    .golf-user {
      img {
        width: 340px;
        height: 340px;
      }
    }
    .tutorial-notification__div {
      .label--top {
        margin-top: 10px;
      }
    }
    .btn-back {
      width: 23px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  #avatar-men {
    background-image: url("~@/assets/image/icon_profile/profile-bgr-sm.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    .snap {
      width: 315px;
      height: 45px;
      font-size: 16px;
    }
    .btn-choose-img {
      width: 315px !important;
    }

    .button-back {
      width: 315px !important;
    }

    .golf-user {
      img {
        width: 315px;
        height: 315px;
      }
    }

    .btn-back {
      width: 14px;
      height: 23px;
    }

    .content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 325px) {
  #avatar-men {
    .snap {
      width: 255px;
      height: 45px;
    }

    .btn-choose-img {
      width: 255px !important;
    }

    .button-back {
      width: 255px !important;
    }

    .golf-user {
      img {
        width: 255px;
        height: 255px;
      }
    }
  }
}
</style>
