<template>
  <div>
    <div class="image-profile text mb-3 f-w6">こちらでよろしいですか？</div>
    <div class="d-flex justify-content-center w-100">
      <div class="summary summary-single">
        <img :src="propsUser.image_url" alt="" class="summary__img" />
        <div
          class="panel-border position-absolute"
          style="background-color: unset"
        >
          <div
            class="position-relative w-100 h-100 d-flex justify-content-center"
          >
            <div
              class="golf-avatar"
              :class="{
                'golf-avatar-white':
                  rank == FEMALE_PREGO_RANK.WHITE ||
                  rank == MALE_PREGO_RANK.WHITE ||
                  rank == null,
                'golf-avatar-red':
                  rank == FEMALE_PREGO_RANK.RED || rank == MALE_PREGO_RANK.RED,
                'golf-avatar-blue':
                  rank == FEMALE_PREGO_RANK.BLUE ||
                  rank == MALE_PREGO_RANK.BLUE,
                'golf-avatar-green':
                  rank == FEMALE_PREGO_RANK.GREEN ||
                  rank == MALE_PREGO_RANK.GREEN,
                'golf-avatar-silver':
                  rank == FEMALE_PREGO_RANK.SILVER ||
                  rank == MALE_PREGO_RANK.SILVER,
                'golf-avatar-gold':
                  rank == FEMALE_PREGO_RANK.GOLD ||
                  rank == MALE_PREGO_RANK.GOLD,
                'golf-avatar-black':
                  rank == FEMALE_PREGO_RANK.BLACK ||
                  rank == MALE_PREGO_RANK.BLACK ||
                  rank == 'PG'
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center image-profile">
      <button @click.prevent="acceptCrop()" class="btn-logout">はい</button>
      <button @click="closeModal()" class="btn-cancel-logout">いいえ</button>
    </div>
  </div>
</template>

<script>
import { MALE_PREGO_RANK, FEMALE_PREGO_RANK } from "@/utils/const";
export default {
  name: "ImageProfile",
  props: {
    propsUser: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rank: null,
      FEMALE_PREGO_RANK: FEMALE_PREGO_RANK,
      MALE_PREGO_RANK: MALE_PREGO_RANK
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    acceptCrop() {
      this.$emit("acceptModal");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";

.image-profile {
  margin-top: 30px;
  &.text {
    font-size: 16px;
  }
}

.btn-logout {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 20px;
}
.btn-cancel-logout {
  width: 155px;
  height: 45px;
  background-color: #e4e4e4;
  color: #000000;
  font-size: 16px;
  border-radius: 5px;
}
.summary {
  * {
    padding: 0;
    margin: 0;
  }
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  height: 100%;
  overflow: hidden;
  box-shadow: #26262680 2px 3px 4px;
  &__img {
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    width: calc(100%);
    height: 100%;
    object-fit: cover;
    left: 0;
    pointer-events: none;
  }
  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    outline: none;
    box-shadow: none;
    background: none;
    &--inactive {
      .button__icon {
        color: $white;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--active {
      .button__icon {
        color: $orange-star;
      }
    }
  }
  .panel-border {
    position: absolute;
    bottom: 0;
    z-index: 20;
    .golf-avatar {
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-size: contain;
      &-white {
        background-image: url("../assets/image/icon_avatar/prego-white.svg");
      }
      &-black {
        background-image: url("../assets/image/icon_avatar/prego-black.svg");
      }
      &-red {
        background-image: url("../assets/image/icon_avatar/prego-red.svg");
      }
      &-blue {
        background-image: url("../assets/image/icon_avatar/prego-blue.svg");
      }
      &-green {
        background-image: url("../assets/image/icon_avatar/prego-green.svg");
      }
      &-silver {
        background-image: url("../assets/image/icon_avatar/prego-silver.svg");
      }
      &-gold {
        background-image: url("../assets/image/icon_avatar/prego-gold.svg");
      }
    }
  }
}

.summary-single {
  width: 100%;
  height: 335px;
  width: 335px;
  .button {
    &__icon {
      width: 35px;
      height: 35px;
    }
  }
  .panel-border {
    right: 18px;
    bottom: 0;
    width: 87px;
    height: 117px;
    .best_score {
      top: 22px;
      font-size: 15px;
    }
    .avg_score {
      bottom: 3px;
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 1080px) {
  .image-profile {
    &.text {
      font-size: 18px;
    }
  }
  .summary-single {
    width: 408px;
    height: 408px;
    .button {
      &__icon {
        width: 45px;
        height: 45px;
      }
    }
    .panel-border {
      right: 18px;
      bottom: 0;
      width: 95px;
      height: 125px;
      .best_score {
        top: 24px;
        font-size: 16px;
      }
      .avg_score {
        bottom: 3px;
        font-size: 10px;
      }
    }
  }
}
</style>
