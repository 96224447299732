<template>
  <div
    class="offer-select"
    :tabindex="tabindex"
    @click="show = !show"
    @blur="show = false"
    :class="show && options.length ? 'active' : ''"
  >
    <div class="offer-select__value">{{ titleOfSelected }}</div>
    <div class="offer-select__options">
      <div
        class="offer-select__item"
        :class="key === value ? 'actived' : ''"
        v-for="(item, key) of options"
        :key="key"
        @click="chooseOption(key)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchProfile",
  props: {
    options: {
      type: Array,
      require: true
    },
    obligatory: {
      type: Boolean,
      require: true
    },
    value: {
      require: false
    },
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  computed: {
    titleOfSelected: function() {
      let title = "";
      if (this.value != null) {
        title = this.options[this.value].name;
      } else {
        title = "";
      }

      return title;
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    chooseOption(key) {
      if (this.value == key && this.obligatory) {
        key = null;
      }
      this.$emit("select-option", key);
    }
  }
};
</script>

<style lang="scss" scoped>
.offer-select {
  height: 40px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    right: 17px;
    top: calc(50% - 3px);
    border-top: 6px solid #9c9c9c;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: ease 0.5s;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.active {
    .offer-select__options {
      opacity: 1;
      transform: scaleY(1);
      box-shadow: none;
      outline: none;
    }
    &::after {
      transform: rotate(180deg);
    }
  }
  &__value {
    height: 100%;
    width: 100%;
    font-family: "Hiragino Kaku Gothic Pro W3";
    font-weight: normal;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin-bottom: 3px;
    font-size: 14px;
  }

  &__options {
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 100%;
    transition: ease 0.3s;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    transform-origin: top;
    transform: scaleY(0);
    box-shadow: 0 0 15px #00000029;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: normal;
  }

  &__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    font-size: 14px;
    font-family: "Hiragino Kaku Gothic Pro W3";
    &.actived {
      background-color: #e5e5e5;
    }
    &:hover {
      background-color: #e5e5e5;
    }
  }
}

@media screen and (min-width: 1080px) {
  .offer-select {
    height: 50px;
    font-size: 16px;
    &__value {
      font-size: 16px;
    }
    &__item {
      padding: 0 18px;
      font-size: 16px;
    }
  }
}
</style>
