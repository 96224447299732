<template>
  <div id="participate-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="participate-tutorial">
        <div class="participate f-maru">
          <div
            class="text-left participate-btn"
            @click="$emit('back-tutorial')"
          >
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-back.svg')"
              alt=""
            />
          </div>
          <p class="title f-maru">ご希望の合流方法を教えてください！</p>
          <div class="participate__div text-left">
            <p class="participate__p f-maru">合流方法</p>
            <SelectOption
              :propsOptions="participations"
              :propsName="'合流方法'"
              :propsRules="{
                required: true
              }"
              :propsStyle="'medium'"
              @emitOption="chooseOption($event, 'method')"
              v-model="form.method"
              @showOption="showOption()"
              :propsId="'participate'"
              :propsShow="show"
            />
            <p class="participate__note text-left" v-if="propsSex == 2">
              ※ピックアップとは、「車で迎えに来てくれること」です。
            </p>
            <p class="participate__note text-left" v-else>
              ※ピックアップとは、「車で迎えに行くこと」です。
            </p>
            <span class="error text-left" v-if="error_method">{{
              error_method
            }}</span>
          </div>
          <button type="submit" @click="nextTutorial">
            <div
              class="position-relative d-flex align-items-center button-back"
            >
              <div class="text-center posision-absolute w-100 text-center">
                次へ
              </div>
              <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
            </div>
          </button>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import SelectOption from "@/components/SelectOption";

export default {
  name: "ParticipateTutorial",
  components: {
    SelectOption: SelectOption
  },
  props: {
    propsSex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      error_method: null,
      show: false,
      participations: [
        {
          name: "ピックアップ",
          value: 1
        },
        {
          name: "現地集合",
          value: 2
        }
      ],
      form: {
        method: ""
      }
    };
  },
  methods: {
    chooseOption(data, name) {
      this.form[name] = data;
      this.reflectData(name);
      this.error_method = null;
      this.show = false;
    },
    blur(e) {
      if (!e.target.closest("#participate")) {
        this.show = false;
      }
    },
    showOption() {
      this.show = !this.show;
    },
    nextTutorial() {
      if (this.form.method == "") {
        this.error_method = "合流方法は必須項目です";
        return;
      }
      this.$emit("tutorialParticipate", this.form);
    },
    reflectData(data) {
      this.$emit("emitReflect", {
        name: data,
        value: this.form[data]
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/men/tutorial/participateTutorial.scss";
@import "@/assets/scss/_fontFamily.scss";
</style>

<style lang="scss" scoped>
#participate-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
}
</style>
