<template>
  <div id="area-tutorial-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="area-tutorial d-flex justify-content-center">
        <div class="area f-maru">
          <div class="text-left" @click="$emit('back-tutorial')">
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-back.svg')"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(nextTutorial)">
              <p class="title f-maru">あなたのプレーエリアを教えてください！</p>
              <div class="area__div m-auto">
                <p class="area__p text-left f-maru">プレーエリア</p>
                <SelectOption
                  :propsOptions="areas"
                  :propsName="'プレーエリア'"
                  :propsRules="{
                    required: true,
                    'min-option': 2
                  }"
                  :propsShowMessage="false"
                  :propsStyle="'medium'"
                  :propsType="'multi'"
                  @emitOption="chooseOption($event, 'golfAddress')"
                  v-model="form.golfAddress"
                  @showOption="showOption()"
                  :propsId="'area'"
                  :propsShow="show"
                />
                <p class="error text-left">2ヶ所以上、選択してください。</p>
              </div>
              <button type="submit">
                <div
                  class="
                    position-relative
                    d-flex
                    align-items-center
                    button-back
                  "
                >
                  <div class="text-center posision-absolute w-100 text-center">
                    次へ
                  </div>
                  <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
                </div>
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import SelectOption from "@/components/SelectOption";

export default {
  name: "AreaTutorial",
  components: {
    SelectOption: SelectOption
  },
  props: {
    propsOptions: {
      type: Array,
      default: null
    }
  },
  created() {
    this.areas = this.propsOptions.map(function(option) {
      return {
        name: option.name,
        value: option.golf_area_id
      };
    });
  },
  data() {
    return {
      areas: [],
      show: false,
      form: {
        golfAddress: ""
      }
    };
  },
  methods: {
    chooseOption(data, name) {
      this.form[name] = data;
      this.reflectData(name);
    },
    showOption() {
      this.show = !this.show;
    },
    blur(e) {
      if (!e.target.closest("#area")) {
        this.show = false;
      }
    },
    nextTutorial() {
      if (this.form.golfAddress.length > 1)
        this.$emit("tutorialArea", this.form);
    },
    reflectData(data) {
      this.$emit("emitReflect", {
        name: data,
        value: this.form[data]
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/men/tutorial/areaTutorial.scss";
@import "@/assets/scss/_fontFamily.scss";
</style>
<style lang="scss" scoped>
#area-tutorial-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
}
</style>
