<template>
  <div :id="propsId" class="select-div">
    <div class="select-option">
      <div class="select select--small position-relative">
        <div
          class="select__div d-flex align-items-center"
          :class="isShowOptions ? 'actived' : ''"
          @click="showOptions"
        >
          <div
            class="
              select__input
              f-maru
              select__input--medium
              d-flex
              align-items-center
            "
          >
            {{ text }}
          </div>
        </div>
        <transition name="slide">
          <ul
            class="select__ul select__ul--medium"
            id="select"
            v-if="propsShow"
          >
            <li
              class="select__li f-maru"
              v-for="(option, index) in propsOptions"
              :key="index"
              @click="chooseOption(option)"
              :class="{
                active:
                  (Array.isArray(value) && value.includes(option.value)) ||
                  value === option.value,
                disabled:
                  !(
                    (Array.isArray(value) && value.includes(option.value)) ||
                    value === option.value
                  ) && value.length >= 5,
                'select__li--medium': propsStyle === 'medium',
                'select__li--area': propsId != 'area'
              }"
            >
              {{ option.name }}
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectOption",
  data() {
    return {
      isShowMessage: this.propsShowMessage,
      isShowOptions: false,
      text: this.propsDefaultValue ?? "",
      name: "",
      value: this.propsDefaultValue ?? "",
      errors: null
    };
  },
  props: {
    propsShow: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      require: null,
      default: 0
    },
    propsId: {
      type: String,
      default: null
    },
    propsErrors: {
      type: String,
      default: null
    },
    propsOptions: {
      type: Array,
      default: null
    },
    propsRules: {
      type: Object,
      default: null
    },
    propsName: {
      type: String,
      default: null
    },
    propsStyle: {
      type: String,
      default: null
    },
    propsType: {
      type: String,
      default: null
    },
    propsDefaultValue: {
      type: Number,
      default: null
    },
    propsShowMessage: {
      type: Boolean,
      default: null
    },
    propsImperative: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    blur() {
      this.isShowOptions = false;
    },
    showOptions() {
      // this.isShowOptions = !this.isShowOptions;
      this.$emit("showOption");
    },
    chooseOption(data) {
      if (this.propsType === "multi") {
        if (!Array.isArray(this.value) || !Array.isArray(this.name)) {
          this.name = [];
          this.value = [];
        }
        if (!this.value.includes(data.value)) {
          this.name.push(data.name);
          this.value.push(data.value);
        } else {
          this.name = this.name.filter(function(item) {
            return item !== data.name;
          });
          this.value = this.value.filter(function(item) {
            return item !== data.value;
          });
        }
        this.text = this.name.join(", ");
      } else {
        if (this.value == data.value && this.propsImperative) {
          this.text = "";
          this.value = "";
          this.$emit("emitOption", this.value);
          this.isShowOptions = false;
        } else {
          this.text = data.name;
          this.value = data.value;
          this.isShowOptions = false;
        }
      }
      this.$emit("emitOption", this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/selectOption.scss";
@import "@/assets/scss/_fontFamily.scss";
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
</style>
