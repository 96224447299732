<template>
  <div id="history-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="history-tutorial">
        <div class="text-left history-btn" @click="$emit('back-tutorial')">
          <img
            class="btn-back"
            :src="require('@/assets/image/arrow-back.svg')"
            alt=""
          />
        </div>
        <div class="history f-maru">
          <p class="title f-maru">あなたのゴルフ歴を教えてください！</p>
          <div class="history__div text-left">
            <p class="history__p f-maru">ゴルフ歴</p>
            <SelectOption
              :propsOptions="histories"
              :propsName="'ゴルフ歴'"
              :propsRules="{
                required: true
              }"
              :propsStyle="'medium'"
              @emitOption="chooseOption($event, 'golfHistory')"
              v-model="form.golfHistory"
              @showOption="showOption()"
              :propsId="'history'"
              :propsShow="show"
            />
            <span class="error" v-if="error_history">{{ error_history }}</span>
          </div>
          <button type="submit" @click="nextTutorial">
            <div
              class="position-relative d-flex align-items-center button-back"
            >
              <div class="text-center posision-absolute w-100 text-center">
                次へ
              </div>
              <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
            </div>
          </button>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import SelectOption from "@/components/SelectOption";

export default {
  name: "HistoryTutorialMen",
  components: {
    SelectOption: SelectOption
  },
  props: {
    propsOptions: {
      type: Array,
      default: null
    }
  },
  created() {
    this.histories = this.propsOptions.map(function(option) {
      return {
        name: option.name,
        value: option.golf_experience_id
      };
    });
  },
  data() {
    return {
      error_history: null,
      show: false,
      histories: [],
      form: {
        golfHistory: ""
      }
    };
  },
  methods: {
    chooseOption(data, name) {
      this.form[name] = data;
      this.reflectData(name);
      this.error_history = null;
      this.show = false;
    },
    blur(e) {
      if (!e.target.closest("#history")) {
        this.show = false;
      }
    },
    showOption() {
      this.show = !this.show;
    },
    nextTutorial() {
      if (this.form.golfHistory == "") {
        this.error_history = "ゴルフ歴は必須項目です";
      } else {
        this.$emit("tutorialHistory", this.form);
      }
    },
    reflectData(data) {
      this.$emit("emitReflect", {
        name: data,
        value: this.form[data]
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/men/tutorial/historyTutorial.scss";
@import "@/assets/scss/_fontFamily.scss";
#history-men {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
}
</style>

<style lang="scss" scoped>
#history-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
}
</style>
