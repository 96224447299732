<template>
  <div id="sake-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="sake-tutorial">
        <div class="sake sake-pc">
          <div class="text-left" @click="$emit('back-tutorial')">
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-back.svg')"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitTutorial)">
              <p class="title title-pc f-maru">お酒を飲まれますか？</p>
              <div class="sake__div text-left">
                <p class="sake__p p-pc f-maru">お酒</p>
                <SelectOption
                  :propsOptions="histories"
                  @emitOption="chooseOption($event, 'alcohol')"
                  v-model="form.golfHistory"
                  :propsName="'お酒'"
                  :propsStyle="'medium'"
                  :props-rules="{ required: false }"
                  @showOption="showOption()"
                  :propsId="'sake'"
                  :propsShow="show"
                  :propsImperative="true"
                />
              </div>
              <button type="submit">
                <div
                  class="position-relative d-flex align-items-center button-back"
                >
                  <div
                    class="text-center posision-absolute w-100 text-center f-maru"
                  >
                    次へ
                  </div>
                  <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
                </div>
              </button>
            </form>
          </ValidationObserver>
          <div class="skip-padding f-maru" @click="nextTutorial">スキップ</div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import SelectOption from "@/components/SelectOption";

export default {
  name: "SakeTutorialMen",
  components: {
    SelectOption: SelectOption
  },
  props: {
    propsOptions: {
      type: Array,
      default: null
    }
  },
  created() {
    this.histories = this.propsOptions.map(function(option) {
      return {
        name: option.name,
        value: option.alcohol_id
      };
    });
  },
  data() {
    return {
      show: false,
      histories: [],
      form: {
        alcohol: ""
      }
    };
  },
  methods: {
    chooseOption(data, name) {
      this.form[name] = data;
      this.reflectData(name);
      this.show = false;
    },
    showOption() {
      this.show = !this.show;
    },
    blur(e) {
      if (!e.target.closest("#sake")) {
        this.show = false;
      }
    },
    nextTutorial() {
      this.$emit("nextSake");
    },

    submitTutorial() {
      this.$emit("submitSake", this.form);
    },

    reflectData(data) {
      this.$emit("emitReflect", {
        name: data,
        value: this.form[data]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/tutorial/tutorialSake.scss";
@import "~@/assets/scss/_fontFamily.scss";
#sake-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
}
</style>
