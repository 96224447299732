var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"image-profile text mb-3 f-w6"},[_vm._v("こちらでよろしいですか？")]),_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"summary summary-single"},[_c('img',{staticClass:"summary__img",attrs:{"src":_vm.propsUser.image_url,"alt":""}}),_c('div',{staticClass:"panel-border position-absolute",staticStyle:{"background-color":"unset"}},[_c('div',{staticClass:"position-relative w-100 h-100 d-flex justify-content-center"},[_c('div',{staticClass:"golf-avatar",class:{
              'golf-avatar-white':
                _vm.rank == _vm.FEMALE_PREGO_RANK.WHITE ||
                _vm.rank == _vm.MALE_PREGO_RANK.WHITE ||
                _vm.rank == null,
              'golf-avatar-red':
                _vm.rank == _vm.FEMALE_PREGO_RANK.RED || _vm.rank == _vm.MALE_PREGO_RANK.RED,
              'golf-avatar-blue':
                _vm.rank == _vm.FEMALE_PREGO_RANK.BLUE ||
                _vm.rank == _vm.MALE_PREGO_RANK.BLUE,
              'golf-avatar-green':
                _vm.rank == _vm.FEMALE_PREGO_RANK.GREEN ||
                _vm.rank == _vm.MALE_PREGO_RANK.GREEN,
              'golf-avatar-silver':
                _vm.rank == _vm.FEMALE_PREGO_RANK.SILVER ||
                _vm.rank == _vm.MALE_PREGO_RANK.SILVER,
              'golf-avatar-gold':
                _vm.rank == _vm.FEMALE_PREGO_RANK.GOLD ||
                _vm.rank == _vm.MALE_PREGO_RANK.GOLD,
              'golf-avatar-black':
                _vm.rank == _vm.FEMALE_PREGO_RANK.BLACK ||
                _vm.rank == _vm.MALE_PREGO_RANK.BLACK ||
                _vm.rank == 'PG'
            }})])])])]),_c('div',{staticClass:"d-flex justify-content-center image-profile"},[_c('button',{staticClass:"btn-logout",on:{"click":function($event){$event.preventDefault();return _vm.acceptCrop()}}},[_vm._v("はい")]),_c('button',{staticClass:"btn-cancel-logout",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("いいえ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }